import React from 'react';

function App() {
  return (
    <div className="App">
      <div className="container">
        <h1>
          Website
          <br />
          Coming Soon...
          <br />
          or
          <br />
          Later
          <br /><br />
          :)
        </h1>
      </div>
    </div>
  );
}

export default App;
